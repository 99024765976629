export const appHead = {"link":[{"hid":"shortcut","rel":"shortcut icon","type":"image/svg","href":"/icon.svg"},{"hid":"icon","rel":"icon","type":"image/svg","href":"/icon.svg"},{"rel":"dns-prefetch","href":"//thumbs.mattepaint.com","hid":"dns-cdn"},{"rel":"dns-prefetch","href":"//assets-cdn.mattepaint.com","hid":"dns-assets"},{"rel":"preconnect","href":"https://thumbs.mattepaint.com/","hid":"preconnect-cdn"},{"rel":"preconnect","href":"https://assets-cdn.mattepaint.com/","hid":"preconnect-assets"},{"rel":"preconnect","href":"https://assets.calendly.com/","hid":"preconnect-calendly"},{"rel":"dns-prefetch","href":"//assets.calendly.com","hid":"dns-calendly"},{"rel":"preload","as":"font","crossorigin":"anonymous","type":"font/woff2","href":"https://assets-cdn.mattepaint.com/fonts/hinted-Campton-Book.woff2","hid":"font-campton"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"},{"charset":"utf-8"},{"name":"description","content":"The highest quality reference photography for artists, architecture, visual effects, film and games."},{"hid":"google-signin-scope","name":"google-signin-scope","content":"profile email"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"twitter:card","property":"og:type","content":"summary_large_image"},{"hid":"og:locale","property":"og:locale","content":"en_US"},{"hid":"google-site-verification","name":"google-site-verification","content":"o5GQCdrXjyOCtKsSbGjety7ff-DEpTmxhysXLf0kAPw"},{"property":"og:image","content":"https://assets-cdn.mattepaint.com/seo_home.jpg"}],"style":[],"script":[{"children":"window.$plausible = [];"},{"src":"https://js.stripe.com/v3/","async":true,"defer":true,"priority":"low"},{"src":"https://accounts.google.com/gsi/client","async":true,"defer":true,"priority":"low"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no","title":"MattePaint","titleTemplate":"%s","htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = "https://assets-cdn.mattepaint.com/landing/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true