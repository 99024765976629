import { defineStore } from 'pinia';

export const useBusinessStore = defineStore('business', {
  id: 'business',
  state: () => {
    return {
      _members: [],
      _business: null, 
      _teams: [],
    }
  },
  
  getters: {
    business({ _business = null }) {
      return _business;
    },
    members({ _members = [] }) {
      return _members;
    },
    teams({ _teams = [] }) {
      return _teams;
    },
  },

  actions: {
    GetMembers(params = {}) {
      const { page = 1 } = params;
      const $config = useRuntimeConfig();
      const token = this.getAuthToken();
      const req = new this.$api.BusinessMemberServiceController({ ...$config.public, store: this, client: true, token });
      return req.GetMembers({ page }).catch(error => ({ error }));
    },

    ChangeMemberRole(params = {}) {
      const { memberUuid, role } = params;
      const $config = useRuntimeConfig();
      const token = this.getAuthToken();
      const req = new this.$api.BusinessMemberServiceController({ ...$config.public, store: this, client: true, token });
      return req.ChangeMemberRole({ memberUuid, role }).catch(error => ({ error }));
    },

    ChangeMemberTeam(params = {}) {
      const { memberUuid, teamId } = params;
      const $config = useRuntimeConfig();
      const token = this.getAuthToken();
      const req = new this.$api.BusinessMemberServiceController({ ...$config.public, store: this, client: true, token });
      return req.ChangeMemberTeam({ memberUuid, teamId }).catch(error => ({ error }));
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBusinessStore, import.meta.hot))
}